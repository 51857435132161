<template>
  <a-modal
    title="附件"
    :width="1080"
    :visible="visible"
    :confirmLoading="loading"
    @ok="modalok"
    @cancel="modalcancel"
  >
    <a-spin :spinning="loading">
      <a-form :form="form" v-bind="formLayout">
        <a-row :gutter="[16,16]">
          <a-col :span="24">
            <a-form-item label="图片信息" v-show="false">
              <a-input v-decorator="['url', {rules: [{required: true, message: '必填项，请填写信息'}]}]" />
            </a-form-item>
            <a-form-item
              label="文件上传"
              extra="文件类型要求：后缀为.jpg,.jpeg,.png的图片或pdf文件"
            >
              <div class="clearfix">
                <a-upload
                  v-decorator="[
                    'url_all',
                    {
                      valuePropName: 'url_all',
                      rules: [{required: true, message: '必填项，请填写信息'}]
                    },
                  ]"
                  name="file"
                  accept=".pdf,.jpg,.jpeg,.png"
                  :action="actionUrl"
                  :data="new_multipart_params"
                  list-type="picture-card"
                  :file-list="fileList"
                  :before-upload="beforeUpload"
                  @preview="handlePreview"
                  @change="handleChange"
                >
                  <div v-if="fileList.length < 1">
                    <a-icon type="plus" />
                    <div class="ant-upload-text">
                      Upload
                    </div>
                  </div>
                </a-upload>
                <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
                  <img alt="example" style="width: 100%" :src="previewImage" />
                </a-modal>
              </div>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import pick from 'lodash.pick'
import { oss_token_list } from '@/api/oss_token'
// 表单字段
const fields = ['id', 'name']
function getBase64 (file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}
export default {
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: Object,
      default: () => null
    }
  },
  data () {
    this.formLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      }
    }
    return {
      new_multipart_params: {
        'key': '',
        'policy': '',
        'OSSAccessKeyId': '',
        'success_action_status': '200',
        'signature': ''
      },
      status: false,
      actionUrl: '',
      previewVisible: false,
      previewImage: '',
      ImgUrl: '',
      fileList: [],
      form: this.$form.createForm(this),
      dictionaries: this.$Dictionaries
    }
  },
  created () {
    console.log('custom modal created', this.form)
    this.fileList = []

    oss_token_list().then(({ data }) => {
      console.log(data, '我是返回值')
      this.new_multipart_params.OSSAccessKeyId = data.accessid
      this.new_multipart_params.policy = data.policy
      this.new_multipart_params.signature = data.signature
      this.actionUrl = 'https://static.jnzbtz.com'
    })
    // 防止表单未注册
    fields.forEach(v => this.form.getFieldDecorator(v))
    // 当 model 发生改变时，为表单设置值
    this.$watch('model', () => {
      this.model && this.form.setFieldsValue(pick(this.model, fields))
    })
  },
  methods: {
    modalcancel (e) {
      this.$emit('cancel')
      /*  this.previewImage = ''
        this.fileList = [] */
    },
    modalok () {
      this.$emit('ok', { actionUrl: this.actionUrl })
      /*  this.previewImage = ''
        this.fileList = [] */
    },
    // 进口图片
    async handlePreview (file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj)
      }
      this.previewImage = file.url || file.preview
      this.previewVisible = true
    },
    handleChange ({ fileList, file }) {
      const fileType = ['pdf', 'jpg', 'jpeg', 'png']
      const suffix = file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase()
      if (fileType.indexOf(suffix) > -1) {
        this.fileList = fileList
        console.log(file.status, '12312313131')
        if (file.status === 'removed') {
          this.form.setFieldsValue({ url: '' })
          this.$message.error('请重新上传')
        }
        if (file.status === 'done') {
          this.status = true
          this.form.setFieldsValue({ url: this.new_multipart_params.key })
        }
      } else {
        this.$message.error('文件类型不符合要求')
      }
    },
    beforeUpload (file) {
      const fileType = ['pdf', 'jpg', 'jpeg', 'png']
      const suffix = file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase()
      // const file_name = file.name.substring(0, file.name.lastIndexOf('.'))
      let env = ''
      console.log(process.env.VUE_APP_API_BASE_URL)
      if (process.env.VUE_APP_API_BASE_URL.indexOf('test') > -1) {
        env = 'test_'
      }
      if (fileType.indexOf(suffix) > -1) {
        this.new_multipart_params.key = env + file.uid.replaceAll('-', '') + '.' + suffix
        return true
      } else {
        this.$message.error('文件类型不符合要求')
        return false
      }
    },
    handleCancel (file) {
      this.previewVisible = false
      console.log(file, '我是删除')
    },
    onChange (value) {
      console.log(value)
    },
    displayRender ({ labels }) {
      return labels[labels.length - 1]
    }
  }
}
</script>
